import React from 'react';
// import sections
import Hero from '../components/sections/Hero-Home';

export default function Home() {
	return (
		<>
			<Hero className="illustration-section-01" />
		</>
	);
}
