import React from 'react';
// import sections
import LongTextArea from '../components/sections/LongTextArea';

export default function Manifesto() {
	const TheContent = (
		<>
			<h1 className="mt-0 mb-16 reveal-from-bottom center-content" data-reveal-delay="200">The Anti-Subjectivist Manifesto</h1><br/>
			<h3 className="mt-0 mb-16 reveal-from-bottom center-content" data-reveal-delay="200">The Case for Consent</h3><br/><br/><br/>
			<h6 className="mt-0 mb-16 reveal-from-bottom center-content" data-reveal-delay="200">By</h6>
			<h6 className="mt-0 mb-16 reveal-from-bottom center-content" data-reveal-delay="200"><i>Christian G. Moore</i></h6>
			<h6 className="mt-0 mb-16 reveal-from-bottom center-content" data-reveal-delay="200">And</h6>
			<h6 className="mt-0 mb-16 reveal-from-bottom center-content" data-reveal-delay="200"><i>Patrick Smith</i></h6>
			<div className="container-sm center-content">
				<p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="400">v1.1</p><br/><br/><br/><br/>
			</div>

			<h3 className="mt-0 mb-16 reveal-from-bottom center-content" data-reveal-delay="500">Preface</h3><br/>
			
			<h6 className="mt-0 mb-16 reveal-from-bottom center-content" data-reveal-delay="500"><i>
				“It is not difficult to avoid death, gentlemen;<br/>
				it is much more difficult to avoid wickedness,<br/>
				for it runs faster than death.”<br/>
				- Socrates, Apology
			</i></h6><br/>

			<div className="container-sm">
				<p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="500">
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The nature of the manifesto is to inform others. To profess some great insight, 
					motivation, or cause, in a manner that is both poignant and powerful. Our goal with this piece is to do just that, for an ethical theory 
					known as “Anti-Subjectivism”, a theory that not only offers a normative framework for determining the “rightness” or “wrongness” of 
					a given set of actions, but also a meta-ethical logic used to evaluate all ethical theories, and a growing breadth of creators and 
					philosophers developing applied instances of the theory.
				</p>

				<p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="500">
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The term Anti-Subjectivism in modern philosophy holds deep connotations to the 
					moral realist positions on the nature of ethical truths – <b>this is not a position of the Anti-Subjectivism we here shall propose</b>, 
					rather this theory was constructed under the notion that ethical realism is an unfounded position, and as a result, none of our 
					arguments will rely on a conception of any ethical “truths” woven into reality, nor power beyond our comprehension which has 
					created any such “truths”. Our goal is not to dupe, swindle, trick, or manipulate our way into a popular adoption of these 
					ideas, instead, we would like to present to you the arguments which have compelled us by the force of reason alone to construct 
					this manifesto for you today. But before we dive into the discussion of Anti-Subjectivism directly, we feel it important to lay 
					out some basic statements about reality that may be useful to help contextualize this philosophy.
				</p>
			</div><br/>

			<h3 className="mt-0 mb-16 reveal-from-bottom center-content" data-reveal-delay="500">Section I:<br/>Establishing the Requirements for Functional Ethics</h3><br/>

			<div className="container-sm">
				<p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="500">
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;We believe there to be three key aspects of the universe and our existence in 
					it that must be directly addressed and agreed on before any ethical theory can be built:
				</p>
				<ol className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="500">
					<li>Reality exists objectively.</li>
					<li>The three laws of logic are a necessary foundation for any ethical theory.</li>
					<li>The default ethical environment of all living creatures is amoral.</li>
				</ol>
			</div><br/>

			<h4 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="500">1. Reality exists objectively.</h4>

			<div className="container-sm">
				<p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="500">
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;For the first claim, it is necessary for any system of normative ethics to accept, 
					even if done so arbitrarily, that reality exists in an objective manner. There exist large swathes of claims in support to the contrary 
					of this position, however, none of these claims bridge the inability for solipsistic (or ideologically similar) worldviews to facilitate 
					any ethical theories. Our response to any individual who would reject this assertion of objective reality is a simple one – there is no 
					amount of argumentation, logic, reasoning, or civil conversation that could bridge the gap between the claims presented in this essay, and 
					a rejection of reality wholesale. If any reader exists as a solipsist and would like to participate in a consistent ethical theory with 
					their imaginary companions, we encourage them to continue reading and perhaps live this theory out in their own world, however, this 
					manifesto will prove highly lacking in cogency and explanatory power for any with a preexisting perspective as such.
				</p>
			</div><br/>

			<h6 className="mt-0 mb-16 reveal-from-bottom center-content" data-reveal-delay="500"><i>
				“Reality is that which, when you stop believing in it, doesn’t go away.”<br/>
				- Philip K. Dick
			</i></h6><br/>

			<h4 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="500">2. The three laws of logic are a necessary foundation for any ethical theory.</h4>

			<div className="container-sm">
				<p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="500">
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Regarding the second claim, without the ability to apply logic and 
					rationalization in a consistent manner, there is no way to construct any ethical theory. Without the laws of identity, non-contradiction, 
					and excluded middle reigning supreme, argumentation, claims, and truth have no meaning. As these laws are necessarily axiomatic, 
					they must be adopted without a logical proof justifying them in themselves. The law of identity, A equals A, for example, does not 
					exist because some other aspect of reality has allowed us to derive this concept and to verify its authenticity outside itself - 
					the law can only be verified by its lack of a counterexample. From the laws of logic, what truths there are of reality can be derived 
					because they are necessarily products of these laws. Any theory of any sort which either does not consider these laws, or either 
					intentionally or unintentionally violates one of the laws of logic, cannot be seriously stated to have any substantive explanatory 
					power or founded basis requiring its existence.
				</p>
			</div><br/>

			<h6 className="mt-0 mb-16 reveal-from-bottom center-content" data-reveal-delay="500"><i>
				“To argue with a man who has renounced the use and authority <br/>
				of reason is like administering medicine to the dead, or <br/>
				endeavoring to convert an atheist by scripture.”<br/>
				- Thomas Paine
			</i></h6><br/>

			<h4 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="500">3. The default ethical environment of all living creatures is amoral.</h4>

			<div className="container-sm">
				<p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="500">
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Finally, for the third claim, the term “state of nature” exists as this amoral 
					environment with a large historical context grounding it firmly in the minds of many philosophers. We will be dedicating a large 
					section of this manifesto to properly outlining the “state of nature” as viewed by Anti-Subjectivists, however, for those with 
					prior knowledge on the topic, it can be tacitly conflated with the Hobbesian conception, still, it is important to note they are 
					not identical. Several key points of differentiation between the Hobbesian and Anti-Subjectivist interpretation of the “state of 
					nature” include the expansion of the “state of nature” to all living creatures in nature- the term nature being synonymous with 
					existing in reality. Be it ant, deer, or human, and the affirmation that no living being is born with any objective moral authority 
					over any other living being thereby, evolving Hobbes’ claim of there being no natural tyrants in a physical sense to a moral sense.
				</p>

				<p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="500">
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Any ethical theory without a proper conception of the “state of nature” which 
					is appropriately addressed is omitting, by their own volition, the most basal state of existence for any living creature which we 
					can directly observe and evince. As the “state of nature” is the broader context from which any ethical theory ought to be in 
					direct consideration when developing both its justifications and claims, Anti-Subjectivism naturally places a large focus on the 
					topic. There is no accurate description of existence where the “state of nature” is omitted, and as such, we believe it to be highly 
					telling of both the credibility and explanatory power of any ethical theory when it fails to address this massive elephant in the 
					room; akin to designing a submarine with no mention of water.
				</p>
			</div><br/>

			<h3 className="mt-0 mb-16 reveal-from-bottom center-content" data-reveal-delay="500">Section II:<br/>Anti-Subjectivism</h3><br/>

			<div className="container-sm">
				<p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="500">
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Ethical philosophies all begin with, whether they realize it or not, the 
					hypothetical imperative that people’s goal is to live above the “state of nature”, the reality in which they are subjected to 
					the force of “might makes right”. On the other hand, science begins with the laws of logic derived from the consistency of the 
					universe and disallows irrational or arbitrary selections in any given theory. Non-arbitrariness ensures that the results of 
					such theories will not produce unforeseen conflict when applied in reality; if the logic of the theory is sound, it stands to 
					reason it will produce the expected outcomes. For ethical theories that wish to accomplish their fundamental goal the same is 
					true. In order to ensure that any given ethical theory will continually facilitate an individual's existence above the “state of 
					nature” we must subject philosophy to the same primacy of logic and absence of arbitrariness utilized by the sciences, thus 
					preventing ourselves from developing theories that when applied in reality prescribe mutually exclusive or conflict-generating 
					behaviors (might-based).
				</p>

				<p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="500">
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Arbitrary selections are, by definition, selections made with no rational 
					basis. Our ability to rationalize is our sole valid tool for making sense of the universe, and a key product of this ability 
					is our capacity to contrast sense data with proposed concepts to identify contradictions. The ability to notice when what is 
					being presented does not match with reality. It is from the rules of logic that we know contradictions cannot exist in reality, 
					and with the rules that we are able to identify them. <b><i>A</i></b> cannot equal both <b><i>A</i></b> and <b><i>Not-A</i></b>; consequently, any contradiction with 
					reality must be a problem with the concept and not with reality. To give an example, if Fred were to believe that the Earth 
					is flat and presented with the wealth of evidence that exists today demonstrating that the Earth is spherical, it is neither 
					the evidence, nor the fact that the Earth is spherical that is the contradiction, but Fred's conception of the flat earth itself.
				</p>

				<p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="500">
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The relationship between arbitrary selections and their proclivity for 
					contradictions is simple. An arbitrary selection is, by definition, unfounded, and thereby it does not require adherence to 
					the laws of logic. As we’ve demonstrated, if a concept is in line with the laws of logic it cannot be the source of any 
					contradiction, but when arbitrariness is asserted the risk for contradictions is undefinable because there is no metric 
					by which to measure a claim made without rational justification. If we arbitrarily assert that 2 + 2 = 5, we are obviously 
					wrong, but only if you were to scrutinize my claim utilizing the rules of mathematics (logical symbology) to do so. On the 
					other hand, if we were to arbitrarily claim that the highest of all goods is the reduction of harm, the claim becomes much 
					more difficult to casually dismiss. The claim is no less arbitrary than my poor mathematical assertion, but when couched in 
					a sea of pseudo-rationalizations that ultimately are defined by subjective preference, the obviousness of the break with 
					rationality is better hidden from those not looking closely. It is this link between arbitrariness and subjective preferences 
					that facilitated the adoption of the name of Anti-Subjectivism. If an act is deemed “good” or “evil” merely because one 
					asserts that it is ipse dixit, then you have made “good” and “evil” ethically meaningless terms, and in so doing robbed very 
					important concepts of any onus to be regarded seriously by those striving toward rationality.
				</p>

				<p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="500">
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;An arbitrary selection is subjective, the reason any person would make any 
					given arbitrary choice is definitionally absent any sort of objective justification, these claims are made because they “feel” 
					right, “seem” like the correct course of action, or “might” be close to the truth, but none of them are founded in any sense that 
					a person striving to be a rational individual would be able to logically validate or to test for soundness. Not all arbitrary 
					selections are something that ought to be avoided. Your favorite tie, Metallica album, flavor of ice cream, and sports team, are 
					all, for most people, arbitrary selections, but it is not the objective of Anti-Subjectivists to rob you of these personal 
					pleasures. When the discussion turns to ethics, however, it is another matter entirely.
				</p>

				<p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="500">
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Arbitrary selection in the context of ethics, either normative or applied 
					(the process for determining what is “good” and “evil” and how to live these determinations in reality), have ramifications that 
					extend past your preference of Tom Brady or Dak Prescott jersey. If an ethical theory makes an arbitrary selection to demand all 
					able-bodied men fight a wild grizzly bear barehanded upon turning eighteen or else be considered “bad”, there are legitimate 
					ramifications to such a prescription. Suddenly, without any approval from the individuals in question, in order to be considered 
					“good” they are required to participate in the mass mauling of the youth, or else. ‘Who would ever enforce that?’ We hear you ask – 
					anyone who arbitrarily chooses to do so. Throughout history, arbitrary ethical theories in the form of various superstitions, 
					religions, and even “scientific” conclusions have been indoctrinated into the masses. Everything from Hitler’s Übermensch to 
					“purify” the human race to the child sacrifices of the Aztecs to bring the rains exist in this category and were adopted and enforced 
					en masse to terrible effect. The existence of an arbitrary element in an ethical theory necessarily introduces the opportunity for 
					further arbitrariness, and this can result in detrimental consequences for those who are expected to live under such circumstances.
				</p>
			</div><br/>

			<h6 className="mt-0 mb-16 reveal-from-bottom center-content" data-reveal-delay="500"><i>
				“The uncontested absurdities of today are the accepted slogans of <br/>
				tomorrow. They come to be accepted by degrees, by precedent, by <br/>
				implication, by erosion, by default, by dint of constant pressure on <br/>
				one side and constant retreat on the other– Until the day when they <br/>
				are suddenly declared to be the country’s official ideology.”<br/>
				- Ayn Rand
			</i></h6><br/>

			<div className="container-sm">
				<p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="500">
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The chain of pseudo-rationalizations must start with an arbitrary selection. 
					If there is no arbitrary rule demanding bouts with bears, there can be no false rationalization that someone ought to enforce 
					the rule. We do not believe it to be hyperbolic in stating that unmitigated usage of arbitrariness is the ultimate downfall of 
					the overwhelming majority of modern ethical theories, <i>if not all of them</i>. When arbitrariness is allowed to fester in places that 
					have no (and can never have) rational justifications for their insertion, it is a catalyst for any number of adverse reactions, 
					interpretations, and results.
				</p>

				<p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="500">
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Make no mistake, Anti-Subjectivism as an ethical theory is <i>not</i> a moral 
					realist position. We do not advocate for, believe in, or provide justification towards an objective moral truth, and because of 
					this, we believe that <b>even the act of following a moral theory is an arbitrary, and thus subjective, preference.</b> This may come 
					as a bit of a shock considering how the beginning of this manifesto has spent the majority of its time lambasting the usage of 
					arbitrariness, but recall the opening statement. “Non-arbitrariness ensures that the results of such theories will not produce 
					unforeseen conflict when applied in reality.” There is a distinction between the fundamental premises any and all systems, be they 
					philosophical or scientific, must initially accept before they can be built and the mechanics of the system itself. This presents 
					the idea of foundational arbitrary selections, the necessary platforms that facilitate the development of all rational systems, 
					in mathematics, science, philosophy, or any other. Reality is real, the rules of logic are sound, our existence ought to be elevated 
					above the state of nature – these are all examples of foundational assumptions that any and every ethical theory <b>must</b> (and do) 
					make in order to function in reality, to satisfy logical functions such as Hume’s Law, which prevent one from rationally deriving 
					an ought from an is. If this accusation against Anti-Subjectivism is considered sufficient to defeat it, then so too are all 
					other ethical theories defeated along with it. Ideas such as Hume’s law are necessarily inescapable and thus far unconquerable. 
					Unlike other contemporary philosophies that are ignorant to, or worse, hand wave away these requirements, we choose to embrace 
					them. This however does not necessitate or justify the free rein of arbitrariness in ethics, any arbitrary statement is subjective 
					after all, which means that any of them could be dismissed for any reason. Our goal is to take the arbitrary foundation that all 
					ethics, and even all sciences and rational debate, must prop themselves upon and add not a single additional arbitrary premise.
				</p>

				<p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="500">
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The rules of logic and the existence of reality are typically upheld by most 
					able-minded individuals you will meet on a day-to-day basis (even if they do not always act in a manner that suggests so). No one 
					bound to reason and evidence expects or believes that their wallet may magically transform into some sort of other object or 
					exists superimposed as both a Ferrari and a wallet at the same time; they know that these things do not, and cannot, happen. It 
					is the desire to elevate oneself above the “state of nature” that is perhaps the least obviously accepted notion, and as such 
					deserves more of our attention today.
				</p>

				<p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="500">
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;First, a brief explanation of the state of nature qua ethics. The state of 
					nature exists as the most basal and only objective layer of reality. You might have colloquially referred to it as “the animal kingdom”, 
					“the rule of the wild”, or “the law of the jungle”, these all essentially encompass the same idea– might makes right, and nothing 
					else. This is the default existence of all living creatures on Earth; deer, ants, panthers, vipers, and even mankind, are all born 
					into this reality and it alone. The moral valuation of the state of nature is null as there is no evidence to support any prevailing 
					moral truths or conceptions that any decision must be made in accordance with, so it is best described as an amoral existence. 
					Because of this, the state of nature is, as you can imagine, a grueling place. There is no popular conception of right or wrong. 
					There are no deontological limitations on an individual’s actions. There is no preference towards one who would wish to act in line 
					with their own sensibilities. There is only survival or death.
				</p>

				<p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="500">
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;In the state of nature, there are no rights. Humanity as a species has spent 
					the last six thousand or more years attempting to separate itself from the state of nature because of these facts of existence, 
					hindered all along the way by arbitrarily asserted higher moral authorities of one manner or another, from gods, to kings, to cults, 
					and collectivizations. This state of nature and the sycophants who have worked to hold people in it are the banes of peaceful, 
					successful coexistence at large. The temptations and capacity for an individual to return to this moral null are always a single 
					decision away, a mere temporary disregard of the primacy of the moral concepts they hold.
				</p>

				<p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="500">
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Earlier, we briefly touched on the idea of “the desire to elevate oneself 
					above the state of nature” being an arbitrary selection. It is a subjective choice to choose to limit one’s actions, whether by 
					whim or to uphold one's preferred ethical theory. This fact helps explain the seemingly unending prevalence of “bad actors” 
					in society at large. We can all think of someone who acts with disregard towards any ethical theory, or a person’s selective 
					adherence towards one or another. The doorstep package thief, the conman, the murderer, the rapist – all of which have a 
					singular trait in common. They individually made the choice to step back into the realm of the state of nature, the realm of 
					might makes right, the realm absent ethical concepts and considerations to achieve some personal goal, others be damned. 
					In the future you may hear a reference to a “state of nature being”, these are those beings. The ones who, for their own expedience 
					or pleasure alone, decide to eschew the conventions of otherwise polite society and return to the realm of the beasts.
				</p>

				<p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="500">
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;One more key fact about the state of nature that is of value to our 
					discussion is the concept of the lack of inherent moral authority in any individual. To rephrase this, there are no natural-born 
					leaders, kings, or tyrants in the state of nature. No one who, by natural or supernatural process, holds an inherent moral 
					authority over others. All creatures have a moral authority of null, no more, no less, and the exact same as any other living 
					creature. For thousands of years of monarchs and divine rulers, the burden of proof in this regard has been unfairly shifted 
					onto their subjects, however, this rationally cannot be the case. A lack of individual moral superiority is merely a matter of 
					absence of evidence to the contrary, but it holds massive ramifications for ethical theories in their construction of society.
				</p>

				<p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="500">
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;From all of this we are presented with the first true question of 
					Anti-Subjectivism; how do we elevate ourselves above the state of nature without introducing further arbitrariness? Thankfully 
					on this occasion reality has provided us with enough facts to discern exactly what it is we are trying to avoid, the “might 
					makes right” reality of the state of nature, so the first step is to identify what it is about this existence we are trying 
					to escape from. There are many preferential answers that can help to frame this idea, “I want to be able to feel safe in my town.”, 
					“I don’t want my things to be taken.”, “I would like to avoid being swindled.” And many more ideas such as this, but all of them have 
					a single connecting thread – some sort of violation of personal autonomy and/or property. This is <i>what</i> “might makes right”, the acts 
					that are justified merely by the power to commit them; acting on other people and their property without their consent. What most 
					distinctly separates the state of nature and existence above it is this conception of the “self”, “mine”, that which is rightly yours 
					or a part of your existence, and your “right” to maintain those aspects until you choose to part with them. In the state of nature, 
					no such idea exists- it cannot exist in a world where you are but a clubbing away from losing your own life and where no system exists 
					by which to dissuade anyone from doing so. But, for the sake of logical rigor, let’s approach this from another angle.
				</p>

				<p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="500">
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;If it is the rule of the state of nature for might to make right, it is 
					logical to assume that the negation of the state of nature would be the negation of this idea, might does not make right. The 
					question then becomes, what does this negation mean in practice? If before it was permissible to take your neighbor's coconuts 
					by force to quench your thirst merely because you were strong enough to do so, in the negation it is no longer permissible to 
					do so. The opposite of taking by force is trading consensually (taking without force). Consent is the negation of force, the 
					negation of the state of nature. The state of nature cannot coexist with consent. Given the options of violent and consensual 
					actions towards the coconut owner and their property, only the latter choice would be permissible under this context. We 
					can condense this negation and all of its ideas into a more operable phrase, the initiation of force is not permissible, or to 
					put it yet another way, all ethical interactions must be consensual. <b>This is the normative ethical claim of Anti-Subjectivist 
					ethics.</b>
				</p>

				<p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="500">
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Consent is defined here as one's agreement or permission for something to 
					happen to or be done to their person and/or property. This idea is universalizable, which is a key component of its function inside 
					a non-arbitrary framework of morally equal individuals. All individuals, at all times, in all places, must be engaged with 
					consensually, unless that individual had returned to the state of nature by failing to reciprocate the primacy of consent. This is 
					because the only way someone could act on others in violation of consent (ex: to act violently) whilst still demanding that others 
					continue to observe and respect their own consent would be if they based that demand on a claim that they somehow possessed greater 
					moral authority; a claim that we’ve already established cannot and has never been substantiated. A violent person has no rational 
					defense to others not treating them likewise. Consent is universalizable because it is impossible for it to be otherwise and as such, 
					if one wishes to remain above the state of nature they must engage in consent reciprocally. On top of this, there are no additional 
					claims that can be extracted from the state of nature as it is defined by the singular “might makes right” attribute, and as such, 
					consent is the only normative claim of the theory. There is nowhere else to build additional rules that other individuals ought to conform 
					to so that they may live above the state of nature without further arbitrary selections. Consent is king.
				</p>

				<p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="500">
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>And that’s the end of the road.</b>
				</p>

				<p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="500">
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;“What?!” We hear you cry, “There are plenty of theories that take consent into 
					account!” We’re inclined to agree to an extent. There are a number of theories that, to some degree, facilitate consensual 
					interactions between individuals – <b>but not without further arbitrary claims that allow for consent to be violated</b>. Let’s take, 
					for example, Act Utilitarianism, not because this theory is widely prevalent or accepted, but because its simple formulation allows 
					us to dissect this observation more clearly. Under Act Utilitarianism, the fundamental principle is to minimize harm towards the 
					maximal number of individuals. On the day-to-day under this rule, you could engage in several consensual interactions, a casual 
					conversation with your neighbor, trading some fruit from your garden for a fresh cut of beef – however, this rule also facilitates 
					an unfathomably large number of nonconsensual actions as well, and this is the primary distinction between Anti-Subjectivism and the 
					rest of ethics. For anti-subjectivism, consent is the rule, for the other theories, consent is merely a temporary phenomenon facilitated 
					by other arbitrary rules.
				</p>

				<p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="500">
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Consent is the most antithetical concept to the state of nature imaginable. As 
					we have already beaten the horse to death regarding the intricacies of the state of nature, we’ll skip the lengthy tirade one could 
					derive simply from this relation, and instead move towards the implications of this idea. If consent, as the negation of the state 
					of nature, is the path by which we elevate ourselves above it, we have presented a whole swathe of applied ethical necessities into 
					our day-to-day interactions with others, and the work on the applied ethics is already vast, but there is one key advantage to the 
					focus on consent that we believe is too often overlooked in the conversation. Flexibility.
				</p>

				<p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="500">
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Under the Anti-Subjectivist theory, your capacity to live your life with others 
					is limited only by your capacity to maintain consensual relations. This means your arbitrarily preferred society is merely an 
					agreement away. If you would like to live in a community much like that which you live in today, where the town pays for garbage 
					service, has regular patrols of security, a designated body that organizes the infrastructure and education – all of this is a few 
					handshakes away. If you would like to live in a commune, pooling the resources of your community's labor and dividing them equally 
					across the members in order to achieve total equilibrium – as long as all the individuals involved continue to provide their consent, 
					it too is a few handshakes away. We will state it again, whatever society you would like to live in, Anti-Subjectivism is limited only 
					by your capacity to find others who wish to join you and to leave those who don't, who do not or who no longer consent, completely out 
					of it.
				</p>

				<p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="500">
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;As we begin to close, we find it important to note that this is not some utopian 
					idea to end all the woes of mankind on Earth. There will be state of nature beings who violate others' consent, there will still be 
					those with less and those with more, there will still be pain, suffering, misery, and strife, some aspects of reality we cannot simply 
					wave away with ideas and words typed on a keyboard. However, unlike what the rest of ethics can provide you, you will have true 
					autonomy that you can utilize and rightly defend from those who would wish to take it from you. Many will critique Anti-Subjectivism 
					as regressive – a notion we won’t even stress in dismissing. If the claim is that Anti-Subjectivism could lead society away from the 
					arbitrary rules, guides, or standards that result in the daily violations of personal consent we see today, then it is certainly 
					regressive, <b>and regressive in the best way possible</b>, regressive in the same way we’ve already regressed from slavery, child sacrifice, 
					and the ideas of the Übermensch.
				</p>
			</div><br/>

			<h3 className="mt-0 mb-16 reveal-from-bottom center-content" data-reveal-delay="500">Section III:<br/>Answering the Call of Consent</h3><br/>

			<div className="container-sm">
				<p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="500">
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;We will end with a call to action, if you will. Even if you are unconvinced with 
					the claims provided in this manifesto, the next time you are engaged in thought or conversation over ethics, play a game of “spot the 
					arbitrariness”. There are a number of understandable and empathic reasons why one might be opposed to the connotations or applied ethics 
					of Anti-Subjectivist theory, but it is up to each of us to determine what is important. Consent is something that the vast majority 
					already claim to value both in word and action, it is up to you whether you choose to run that line of thought to its logical conclusion 
					or to reject reason by arbitrary justification toward arbitrary ends. Regardless of your decision, we will continue to respect the consent 
					of all the people we meet and interact with that reciprocate such respect toward ours. Our only hope is that you will join us, and help 
					us to build a community of like-minded individuals above the state of nature, as individuals connected by a common cause. Consensually, 
					of course.
				</p>

				<p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="500">
					For more information, visit AntiSubjectivism.com
				</p>
			</div><br/>
		</>
	)
	
	return (
		<>
			<LongTextArea className="illustration-section-01" theContent={TheContent} />
		</>
	);
}
