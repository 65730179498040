import React from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';

const propTypes = {
	...SectionProps.types
}

const defaultProps = {
	...SectionProps.defaults
}

export default function LongTextArea({className, topOuterDivider, bottomOuterDivider, topDivider, bottomDivider, hasBgColor, invertColor, theContent, ...props}) {
	const outerClasses = classNames(
		'hero section', topOuterDivider && 'has-top-divider', bottomOuterDivider && 'has-bottom-divider',
		hasBgColor && 'has-bg-color', invertColor && 'invert-color', className
	);

	const innerClasses = classNames('hero-inner section-inner', topDivider && 'has-top-divider', bottomDivider && 'has-bottom-divider');

	return (
		<section {...props} className={outerClasses}>
			<div className="container-sm">
				<div className={innerClasses}>
					<div className="hero-content">
						{theContent}
					</div>
				</div>
			</div>
		</section>
	);
}

LongTextArea.propTypes = propTypes;
LongTextArea.defaultProps = defaultProps;
